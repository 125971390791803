<template>
  <v-layout column>
    <v-layout
      align-center
      class="delegate"
      :style="{ borderLeft: `4px solid #${value.color}` }"
    >
      <DefaultSubtitle :explicit="false">{{ value.name }}</DefaultSubtitle>
      <v-spacer></v-spacer>
      <v-switch
        v-if="value.medical_addon_enabled"
        v-model="value.access_to_other_cards"
        class="mt-0 mr-6"
        label="Dostęp do kart innych terapeutów"
        color="expressive_green"
        inset
        hide-details
        :disabled="value.disabled_cards_access"
        :loading="loading"
        :ripple="false"
      >
      </v-switch>
      <v-switch
        v-model="value.medical_addon_enabled"
        class="mt-0"
        label="Pracownik medyczny"
        color="expressive_green"
        inset
        hide-details
        :disabled="value.disabled"
        :loading="loading"
        :ripple="false"
      ></v-switch>
    </v-layout>
    <v-divider v-if="!isLast"></v-divider>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("update", this.value);
      },
      deep: true,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
  },
};
</script>

<style scoped>
.delegate {
  min-height: 44px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 24px;
  margin-right: 24px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
